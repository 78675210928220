// src/pages/ProfilePage.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './ProfilePage.css';

const ProfilePage = () => {
  const location = useLocation();
  const user = location.state?.user; // Получаем данные пользователя из state
  const [purchases, setPurchases] = useState([]); // Состояние для хранения покупок пользователя

  useEffect(() => {
    if (user) {
      fetch(`/purchase/user/${user.id}`, { credentials: 'include' })
        .then(res => {
          if (!res.ok) {
            throw new Error(`Ошибка: ${res.statusText}`);
          }
          return res.json();
        })
        .then(data => {
          setPurchases(data.purchases);
        })
        .catch(error => {
          console.error('Error fetching purchases:', error);
          alert('Ошибка при загрузке покупок: ' + error.message);
        });
    }
  }, [user]);
  

  return (
    <div className="profile-page">
      <Header />
      <div className="content">
        {user ? (
          <>
            <div className="profile-info">
              <h1>Профиль пользователя</h1>
              <img src={user.picture} alt="User Profile" className="profile-photo" />
              <p><strong>Имя:</strong> {user.firstName} {user.lastName}</p>
              <p><strong>Email:</strong> {user.email}</p>
            </div>
            <div className="user-purchases">
              <h2>Мои покупки</h2>
              {purchases.length > 0 ? (
                <ul>
                  {purchases.map(purchase => (
                    <li key={purchase.id} className="purchase-item">
                      <p><strong>ID Покупки:</strong> {purchase.id}</p>
                      <p><strong>Товары:</strong> {purchase.items.map(item => `${item.title} (x${item.quantity})`).join(', ')}</p>
                      <p><strong>Выполнено:</strong> {purchase.isCompleted ? 'Да' : 'Нет'}</p>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>У вас пока нет покупок.</p>
              )}
            </div>
          </>
        ) : (
          <div className="no-profile">
            <h1>Вы не авторизованы</h1>
            <p>У вас нет профиля. Пожалуйста, войдите в систему.</p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ProfilePage;
