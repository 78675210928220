// src/utils/cartUtils.js
export const addToCart = async (service) => {
  try {
    const response = await fetch('https://bufferka.shop/cart/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ item: service }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Added to cart:', data);
    return data; // Возвращаем данные для дальнейшей обработки
  } catch (error) {
    console.error('Error adding to cart:', error);
    throw error; // Пробрасываем ошибку для обработки в компоненте
  }
};
export const incrementQuantity = (itemTitle) => {
  return fetch('https://bufferka.shop/cart/update-quantity', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ itemTitle, increment: true }),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => data.cart);
};

export const decrementQuantity = (itemTitle) => {
  return fetch('https://bufferka.shop/cart/update-quantity', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ itemTitle, increment: false }),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => data.cart);
};

export const removeItem = (itemTitle) => {
  return fetch('https://bufferka.shop/cart/remove', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ itemTitle }),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => data.cart);
};
