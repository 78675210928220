// src/pages/services/pubg_mobile/MetroEscort.js
import React, { useEffect } from 'react';
import Header from '../../../components/Header';
import UnderHeader from '../../../components/UnderHeader';
import Footer from '../../../components/Footer';
import ServiceInfoCard from '../../../components/ServiceInfoCard';
import { addToCart } from '../../../utils/cartUtils';

function RaidHelper() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы до начала
  }, []);

  return (
    <div className="service-page">
      <Header />
      <UnderHeader title="Поощь с рейдами"></UnderHeader>
      <div className="services">
        <ServiceInfoCard
          title="Пакет 'База'"
          description="Предоставляем одного человека на весь рейд. Рейд заканчивается после присвоения вам шкафа
          и переноса ресурсов домой"
          price="6"
          onAddToCart={addToCart}
        />
        <ServiceInfoCard
          title="Пакет 'малое превосходство'"
          description="Предоставляем 3 человек на весь рейд. Рейд заканчивается после присвоения вам шкафа
          и переноса ресурсов домой"
          price="10"
          onAddToCart={addToCart}
        />
        <ServiceInfoCard
          title="Пакет 'минус клан'"
          description="Предоставляем 4-8 человек на весь рейд. Рейд заканчивается после присвоения вам шкафа
          и переноса ресурсов домой"
          price="20"
          onAddToCart={addToCart}
        />
      </div>
      <Footer />
    </div>
  );
}

export default RaidHelper;
