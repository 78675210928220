import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { useNavigate } from 'react-router-dom';



require('dotenv');
const Header = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://bufferka.shop/api/user', {
        method: 'GET',
        credentials: 'include', // Этот параметр важен для отправки куков
    })
    .then((response) => response.json())
    .then((data) => {
        if (data.email) {
            setIsAuthenticated(true);
            setUser(data);
        } 
        else {
            setIsAuthenticated(false);
        }
    })
    .catch(() => setIsAuthenticated(false));
}, []);


  const handleLogout = () => {
    fetch('https://bufferka.shop/auth/logout', {
        method: 'POST',
        credentials: 'include' // Ensure cookies are sent with the request
    })
    .then(() => {
        setIsAuthenticated(false);
        setUser(null);
        navigate("/")
    })
    .catch((error) => {
        console.error('Error logging out:', error);
    });
  };

  const handleLogin = () => {
    window.location.href = 'https://bufferka.shop/auth/login';
  };

  const handleCartClick = () => {
    navigate('/cart');  // Переход на страницу корзины
};

  return (
    <header className="header">
      <div className="logo">
        <a href="/" className="logo-link">
          <img src="/logo.png" alt="MyLogo" className="logo-image" />
          <span className="logo-text">Bufferka Shop</span>
        </a>
      </div>
      <div className="auth-buttons">
        
      <img onClick={handleCartClick} 
      src={`${process.env.REACT_APP_CART_IMG}`}
       height="30px" 
       alt="cart" 
       style={{margin: '0px 20px', cursor: 'pointer'}} />

        {isAuthenticated ? (
          <>
            <span>
<Link to="/profile" state={{ user }}>
  <img src={user?.picture} alt="User Profile" className='profile-photo' />
</Link>

</span>
            <button className='login_button' onClick={handleLogout}>Log Out</button>
          </>
        ) : (
          <button className='login_button' onClick={handleLogin}>Log in</button>
        )}
      </div>
    </header>
  );
};

export default Header;
