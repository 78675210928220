// CartPage.js
import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus, FaTrash } from 'react-icons/fa';

import Header from '../components/Header';
import Footer from '../components/Footer';
import UnderHeader from '../components/UnderHeader';
import { incrementQuantity, decrementQuantity, removeItem } from '../utils/cartUtils';

import './CartPage.css';

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cartId, setCartId] = useState(null);  // Хранение ID корзины

  useEffect(() => {
    fetch('https://bufferka.shop/cart/view', {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        setCartItems(data.items || []);
        calculateTotalPrice(data.items || []);
        setCartId(data.cartId);  // Сохранение ID корзины
        console.log("Cart ID set:", data.cartId);
      })
      .catch(error => {
        console.error('Error fetching cart:', error);
      });
  }, []);

  const calculateTotalPrice = (items) => {
    const total = items.reduce((sum, item) => sum + parseFloat(item.price) * item.quantity, 0);
    setTotalPrice(total);
  };

  const handleIncrement = (itemTitle) => {
    incrementQuantity(itemTitle)
      .then(updatedCart => {
        setCartItems(updatedCart.items);
        calculateTotalPrice(updatedCart.items);
      })
      .catch(error => console.error('Error incrementing quantity:', error));
  };

  const handleDecrement = (itemTitle) => {
    decrementQuantity(itemTitle)
      .then(updatedCart => {
        setCartItems(updatedCart.items);
        calculateTotalPrice(updatedCart.items);
      })
      .catch(error => console.error('Error decrementing quantity:', error));
  };

  const handleRemove = (itemTitle) => {
    removeItem(itemTitle)
      .then(updatedCart => {
        setCartItems(updatedCart.items);
        calculateTotalPrice(updatedCart.items);
      })
      .catch(error => console.error('Error removing item:', error));
  };

  // const handlePurchase = async () => {
  //   try {
  //     const response = await fetch('/purchase/buy', {
  //       method: 'POST',
  //       credentials: 'include',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  
  //     if (!response.ok) {
  //       throw new Error('Ошибка при создании покупки');
  //     }
  
  //     const data = await response.json();
  //     alert('Покупка успешно завершена!');
  //     setCartItems([]);
  //     setTotalPrice(0);
  //   } catch (error) {
  //     alert('Ошибка при завершении покупки');
  //     console.error(error);
  //   }
  // };
  const handlePurchase = async () => {
    if (!cartId || totalPrice === 0) {
      console.error('Cart ID is null or totalPrice is zero');
      return;
    }
    try {
      const response = await fetch('/payment/initiate', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: totalPrice,  // Общая сумма заказа
          orderId: cartId      // Уникальный идентификатор заказа (корзины)
        }),
      });
  
      const data = await response.json();
      if (data.paymentUrl) {
        // Перенаправляем пользователя на страницу оплаты
        window.location.href = data.paymentUrl;
      }
    } catch (error) {
      console.error('Ошибка при инициации платежа:', error);
    }
  };  

  return (
    <div className="service-page">
      <Header />
      <UnderHeader title="Cart"></UnderHeader>
      <div className="cart-content">
        <div className="cart-items">
          {cartItems.length === 0 ? (
            <p>Корзина пуста.</p>
          ) : (
            <ul>
              <p color='white'>{cartId}</p>
              {cartItems.map((item, index) => (
                <li key={index} className="cart-item">
                  <div className="item-info">
                    <strong className="item-title">{item.title}</strong>
                    <p className="item-description">{item.description}</p>
                  </div>
                  
                  <p className="item-total-price">{item.price * item.quantity} &#36;.</p>
                  <div className="cart-item-actions">
                    <button
                      onClick={() => handleRemove(item.title)}
                      className="remove-button"
                    >
                      <FaTrash />
                    </button>
                    <div className="quantity-control">
                      <button
                        onClick={() => handleDecrement(item.title)}
                        disabled={item.quantity === 1}
                        className="quantity-button"
                      >
                        <FaMinus />
                      </button>
                      <span className="quantity">{item.quantity}</span>
                      <button
                        onClick={() => handleIncrement(item.title)}
                        className="quantity-button"
                      >
                        <FaPlus />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="total-price">
          Общая стоимость: {totalPrice} &#36;.
        </div>
        {cartItems.length > 0 && (
          <button className="purchase-button" onClick={handlePurchase}>
            Оплатить
          </button>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CartPage;
