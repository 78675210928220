// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import RustPage from './pages/RustPage';
import PubgPage from './pages/PubgPage';
import CartPage from './pages/CartPage';
import PaymentSuccess from './pages/payment/PaymentSuccess';
import PaymentFailure from './pages/payment/PaymentFailure';
import MetroEscort from './pages/services/pubg_mobile/MetroEscort';
import MetroShop from './pages/services/pubg_mobile/MetroShop';
import UcShop from './pages/services/pubg_mobile/UcShop';
import SkinShop from './pages/services/rust/SkinShop';
import RaidHelper from './pages/services/rust/RaidHelper';
import Farmbot from './pages/services/rust/Farmbot';
import NightGuard from './pages/services/rust/NightGuard';
import TwitchDrops from './pages/services/rust/TwitchDrops';
import ProfilePage from './pages/ProfilePage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/rust" element={<RustPage />} />
          <Route path="/rust/skin-shop" Component={SkinShop} />
          <Route path="/rust/raid-helper" Component={RaidHelper} />
          <Route path="/rust/farmbot" Component={Farmbot} />
          <Route path="/rust/night-guard" Component={NightGuard} />
          <Route path="/rust/twitch-drops" Component={TwitchDrops} />
        <Route path="/pubg" element={<PubgPage />} />
        <Route path="/cart" element={<CartPage />} />
          <Route path="/pubg/escort-metro" Component={MetroEscort} />
          <Route path="/pubg/metro-shop" Component={MetroShop} />
          <Route path="/pubg/uc-shop" Component={UcShop} />

          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/payment/success" component={PaymentSuccess} />
          <Route path="/payment/failure" component={PaymentFailure} />
      </Routes>
    </Router>
  );
}

export default App;