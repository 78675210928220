// src/components/ServiceInfoCard.js
import React from 'react';
import './ServiceCard.css';
import './ServiceInfoCard.css';


const ServiceInfoCard = ({ title, description, price, onAddToCart }) => {
  const handleAddToCart = async () => {
    try {
      // Проверка авторизации пользователя перед добавлением товара
      const authResponse = await fetch('/api/auth/check', {
        method: 'GET',
        credentials: 'include',
      });

      if (!authResponse.ok) {
        // Если пользователь не авторизован
        alert('Вы не авторизованы. Пожалуйста, войдите в систему.');
        return;
      }

      // Вызов функции добавления в корзину и ожидание ответа
      const data = await onAddToCart({ title, description, price });

      // Проверяем, что запрос завершился успешно и данные получены
      if (data && data.message === 'Item added to cart') {
        alert(`Товар "${title}" успешно добавлен в корзину!`);
      } else {
        alert('Произошла ошибка при добавлении товара.');
      }
    } catch (error) {
      console.error('Ошибка при добавлении товара:', error);
      alert('Произошла ошибка при добавлении товара.');
    }
  };

  return (
    <div className="service-info-card">
      <div className="service-info-content">
        <h2 className="service-info-title">{title}</h2>
        <p className="service-info-description">{description}</p>
        <div className="service-info-footer">
          <span className="service-info-price">{price} &#36;</span>
          <button className="info-button" style={{maxWidth: '200px'}} onClick={handleAddToCart}>
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceInfoCard;
