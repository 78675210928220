// src/pages/services/pubg_mobile/MetroEscort.js
import React, { useEffect } from 'react';
import Header from '../../../components/Header';
import UnderHeader from '../../../components/UnderHeader';
import Footer from '../../../components/Footer';
import ServiceInfoCard from '../../../components/ServiceInfoCard';
import { addToCart } from '../../../utils/cartUtils';

function UcShop() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы до начала
  }, []);

  return (
    <div className="service-page">
      <Header />
      <UnderHeader title="UC Shop"></UnderHeader>
      <div className="services">
        <ServiceInfoCard
          title="Пакет Minimum"
          description="200 UC"
          price="3"
          onAddToCart={addToCart}
        />
        <ServiceInfoCard
          title="Пакет Middle"
          description="1000 UC"
          price="10"
          onAddToCart={addToCart}
        />
        <ServiceInfoCard
          title="Пакет Maximum"
          description="2500 UC"
          price="15"
          onAddToCart={addToCart}
        />
      </div>
      <Footer />
    </div>
  );
}

export default UcShop;
