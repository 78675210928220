// src/pages/services/pubg_mobile/MetroEscort.js
import React, { useEffect } from 'react';
import Header from '../../../components/Header';
import UnderHeader from '../../../components/UnderHeader';
import Footer from '../../../components/Footer';
import ServiceInfoCard from '../../../components/ServiceInfoCard';
import { addToCart } from '../../../utils/cartUtils';

function NightGuard() {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутка страницы до начала
  }, []);

  return (
    <div className="service-page">
      <Header />
      <UnderHeader title="Ночной охранник"></UnderHeader>
      <div className="services">
        <ServiceInfoCard
          title="Ночной охранник по европейскому времени"
          description="Спи-спи. О ресах позаботятся"
          price="10"
          onAddToCart={addToCart}
        />
        <ServiceInfoCard
          title="Ночной охранник по азиатскому времени"
          description="Ночной дозор. За небольшую плату"
          price="10"
          onAddToCart={addToCart}
        />
      </div>
      <Footer />
    </div>
  );
}

export default NightGuard;
